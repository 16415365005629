import { createSlice } from '@reduxjs/toolkit';
import { settings } from 'config';
import { getItemFromStore, setItemToStore } from 'helpers/utils';

interface ConfigState {
  isFluid: string;
  isRTL: boolean;
  isDark: boolean;
  navbarPosition: string;
  disabledNavbarPosition: [];
  isNavbarVerticalCollapsed: boolean;
  navbarStyle: string;
  currency: string;
  showBurgerMenu: boolean;
  showSettingPanel: boolean;
  navbarCollapsed: boolean;
}

interface SetConfigAction {
  type: 'config/setConfig';
  payload: {
    key: string;
    value: any;
    setInStore?: boolean;
  };
}

interface RefreshAction {
  type: 'config/refresh';
}

interface ResetAction {
  type: 'config/reset';
}

type ConfigAction = SetConfigAction | RefreshAction | ResetAction;

const initialState = {
  isFluid: getItemFromStore('isFluid', settings.isFluid),
  isRTL: getItemFromStore('isRTL', settings.isRTL),
  isDark: getItemFromStore('isDark', settings.isDark),
  navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
  disabledNavbarPosition: [],
  isNavbarVerticalCollapsed: getItemFromStore(
    'isNavbarVerticalCollapsed',
    settings.isNavbarVerticalCollapsed
  ),
  navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
  currency: settings.currency,
  showBurgerMenu: settings.showBurgerMenu,
  showSettingPanel: false,
  navbarCollapsed: false,
} as ConfigState;

interface Payload {
  key: keyof ConfigState;
  value: boolean;
  setInStore: boolean;
}
interface Action {
  payload: Payload;
}
export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigR: (state: any, action: Action) => {
      const { key, value, setInStore } = action.payload;

      if (setInStore) {
        setItemToStore(key, value);
      }
      //console.log(state[key], value);

      state[key] = value;
      //console.log(state[key]);
    },
    refresh: (state) => {
      // No-op
    },
    reset: (state): any => {
      localStorage.clear();
      return {
        ...settings,
      };
    },
  },
});

export const { setConfigR, refresh, reset } = configSlice.actions;

export default configSlice.reducer;
