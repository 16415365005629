import { AppContext } from 'context/Context';
import { getColor, getItemFromStore } from 'helpers/utils';
import { useReducer } from 'react';
import { settings } from './config';
import useToggleStyle from './hooks/useToggleStyle';
import { configReducer } from './reducers/configReducer';
import { Provider, store } from './store';

// import { Chart as ChartJS, registerables } from 'chart.js';
// ChartJS.register(...registerables);
type MainProps = { children: React.ReactNode };
const Main: React.FC<MainProps> = (props) => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key: string, value: any) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
        ].includes(key),
      },
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light'),
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      <Provider store={store}>{props.children}</Provider>
    </AppContext.Provider>
  );
};

export default Main;
