import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Imprint } from 'components/custom/Imprint';
import { useSettingsContext } from 'context/useSettingsContext';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import LizenzPage from 'pages/UserSpecificPages/Admin/Lizenz';
import CustomerAdminPage from 'pages/UserSpecificPages/CustomerAdmin/EditCustomer';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from 'Routes';
import routes from '../routing/routes';
import CustomerAdminLayout from './admin/CustomerAdminLayout';
const EditCustomerPage = lazy(
  () => import('pages/UserSpecificPages/Admin/EditCustomer/Index')
);
const ErroPage = lazy(() => import('pages/Error/ErroPage'));
const ShowCustomerPage = lazy(
  () => import('pages/UserSpecificPages/Admin/DetailCustomerPage/Index')
);
const EditUserPage = lazy(
  () => import('pages/UserSpecificPages/Admin/EditUser')
);
const Login = lazy(() => import('pages/Login'));
const Main = lazy(() => import('pages/Main'));
const MainLayout = lazy(() => import('layouts/MainLayout'));
const Error404 = lazy(() => import('components/errors/Error404'));
const ErrorLayout = lazy(() => import('layouts/ErrorLayout'));
const Downloads = lazy(() => import('pages/Downloads'));
const License = lazy(() => import('pages/License'));
const Profile = lazy(() => import('pages/Profile'));
const Company = lazy(() => import('pages/Company'));
const Support = lazy(() => import('pages/Support'));
const Reset = lazy(() => import('pages/Reset'));
const ForgetPassword = lazy(() => import('pages/ForgotPassword'));
const AdminLayout = lazy(() => import('./admin/AdminLayout'));
const TestElement = lazy(() => import('./admin/TestElement'));
const Benutzerliste = lazy(
  () => import('pages/UserSpecificPages/Admin/Benutzerliste')
);
const Kundenliste = lazy(
  () => import('pages/UserSpecificPages/Admin/Kundeliste')
);
const RootNode = () => {
  const { config } = useSettingsContext();
  return (
    <>
      <ErrorBoundary FallbackComponent={ErroPage}>
        <Suspense fallback={<></>}>
          <Routes>
            <Route element={<ProtectedRoute requiresAuth={false} />}>
              <Route element={<AuthSimpleLayout />}>
                <Route path={routes.unsigned.signin} element={<Login />} />
                <Route path={routes.unsigned.reset} element={<Reset />} />
                <Route
                  path={routes.unsigned.forgotPassword}
                  element={<ForgetPassword />}
                />
              </Route>
            </Route>
            <Route element={<ProtectedRoute requiresAuth={true} />}>
              <Route element={<MainLayout />}>
                <Route path={routes.signed.application} element={<Main />} />
                <Route path={routes.signed.download} element={<Downloads />} />
                <Route path={routes.signed.license} element={<License />} />
                <Route path={routes.signed.profile} element={<Profile />} />
                <Route path={routes.signed.company} element={<Company />} />
                <Route path={routes.signed.support} element={<Support />} />
                <Route path={routes.signed.imprint} element={<Imprint />} />
                //#region adminRoutes
                <Route path={routes.admin.index} element={<AdminLayout />}>
                  <Route
                    path={routes.admin.Licenses}
                    element={<LizenzPage />}
                  />
                  <Route
                    path={routes.admin.users}
                    element={<Benutzerliste />}
                  />
                  <Route
                    path={routes.admin.customers}
                    element={<Kundenliste />}
                  />
                  <Route
                    path={routes.admin.edituser}
                    element={<EditUserPage />}
                  />
                  <Route
                    path={routes.admin.showcustomer}
                    element={<ShowCustomerPage />}
                  />
                  <Route
                    path={routes.admin.editcustomer}
                    element={<EditCustomerPage />}
                  />
                </Route>
                //#endregion adminRoutes //#region CustomerAdminRoutes
                <Route
                  path={routes.customeradmin.index}
                  element={<CustomerAdminLayout />}
                >
                  <Route
                    path={routes.customeradmin.editcustomer}
                    element={<CustomerAdminPage />}
                  />
                  <Route
                    path={routes.customeradmin.edituser}
                    element={<EditUserPage />}
                  />
                </Route>
                //#endregion CustomerAdminRoutes
              </Route>
              <Route
                path={routes.signed.application + '/*'}
                element={<Navigate to={routes.signed.notfound} />}
              />
            </Route>
            <Route element={<ErrorLayout />}>
              <Route path={routes.signed.notfound} element={<Error404 />} />
              <Route path={routes.unsigned.imprint} element={<Imprint />} />
            </Route>
            <Route
              path="/"
              element={<Navigate to={routes.unsigned.signin} replace />}
            />
            <Route
              path="*"
              element={<Navigate to={routes.signed.notfound} />}
            />
          </Routes>
        </Suspense>
      </ErrorBoundary>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={config.isDark ? 'dark' : 'light'}
      />
    </>
  );
};

export default RootNode;
