import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import {
  benutzerliste,
  common,
  downloads,
  home,
  labels,
  licenses,
  login,
  profile,
  routes,
  support,
} from './import_locals';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  .use(i18nextPlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'de',
    debug: true,
    resources: {
      de: {
        benutzerliste: benutzerliste.de,
        profile: profile.de,
        translation: common.de,
        login: login.de,
        support: support.de,
        downloads: downloads.de,
        home: home.de,
        licenses: licenses.de,
        routes: routes.de,
        labels: labels.de,
      },
      en: {
        benutzerliste: benutzerliste.en,
        profile: profile.en,
        translation: common.en,
        login: login.en,
        support: support.en,
        downloads: downloads.en,
        home: home.en,
        licenses: licenses.en,
        routes: routes.en,
        labels: labels.en,
      },
      fr: {
        benutzerliste: benutzerliste.fr,
        profile: profile.fr,
        translation: common.fr,
        login: login.fr,
        support: support.fr,
        downloads: downloads.fr,
        home: home.fr,
        licenses: licenses.fr,
        routes: routes.fr,
        labels: labels.fr,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
