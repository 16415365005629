import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import guyWithLaptop from 'assets/img/animated/animation_guy-with-laptop.json';
import IconButton from 'components/common/IconButton';
import useData from 'hooks/useData';
import Lottie from 'lottie-react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import routes from 'routing/routes';
import { RootState } from 'store';
import { UserWithCustomer } from 'types';
import EditCustomer from './EditCustomer';
import EditCustomerHeader from './EditCustomerHeader';

function Animated(): JSX.Element {
  return (
    <Row className="align-items-center">
      <Col className="d-flex justify-content-center">
        <Lottie
          animationData={guyWithLaptop}
          loop={true}
          style={{ height: '220px', width: '220px' }}
        />
      </Col>
    </Row>
  );
}
function NoCustomerFound(): JSX.Element {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigate(-1)}
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
            iconClassName={undefined}
            transform={undefined}
            children={undefined}
          />
        </Card.Header>
      </Card>
      <Card className="text-center">
        <Card.Body className="p-5">
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            {t('something_went_wrong')}
          </p>
          <Animated />

          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            {t('no_customer_linked')}
          </p>

          <hr />
          <p>{t('get_support')}</p>
          <Link
            className="btn btn-primary btn-sm mt-3"
            to={routes.signed.support}
          >
            <FontAwesomeIcon icon={faHome} className="me-2" />
            Support
          </Link>
        </Card.Body>
      </Card>
    </>
  );
}
type Props = {};
export default function CustomerAdminPage({}: Props) {
  const { user } = useSelector((state: RootState) => state);
  const guid = user?.guid;

  const {
    data: UserWithCustomer,
    error,
    loading,
  } = useData<UserWithCustomer>(`/api/Users/${guid}/with-customer`);
  const customer = UserWithCustomer?.customer;
  if (customer === null) return <NoCustomerFound />;
  return (
    <>
      {/* <PageHeader title="Edit Customer" /> */}
      <EditCustomerHeader />
      {customer && <EditCustomer customerGuid={customer?.guid} />}
    </>
  );
}
