import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'components/common/Avatar';
import 'react-loading-skeleton/dist/skeleton.css';
import SoftBadge from 'components/common/SoftBadge';
import { UserDTO, UserModel } from 'types';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CreateUserModal from './CreateUserModal';
const queryString = window.location;

type CustomerUsersDetaisTableProps = {
  users: UserModel[];
  refetch: () => void;
};
const CustomerUsersDetaisTable = ({
  users,
  refetch,
}: CustomerUsersDetaisTableProps) => {
  return (
    <AdvanceTableWrapper
      //@ts-ignore
      rowCount={users.length}
      columns={columns()}
      data={users}
      sortable
      // selection
      pagination={true}
      perPage={8}
      selectionColumnWidth={10}
    >
      <Card className="mb-xl-3">
        <FalconCardHeader
          title="Connected Users"
          titleTag="h6"
          endEl={<CreateUserModal />}
        />

        <Card.Body>
          <AdvanceTable
            //@ts-ignore

            table
            headerClassName="d-none"
            rowClassName=" align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden',
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            //@ts-ignore
            table
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};
type UserItemProps = {
  user: UserDTO;
};

const columns = () => {
  return [
    {
      accessor: 'name',
      Header: 't(customer_key)',
      headerProps: {
        className: 'py-3 ps-0',
      },
      cellProps: {
        className: 'ps-3 w-100',
      },
      Cell: (rowData: any) => {
        return <UserItem user={rowData.row.original} />;
      },
    },
    {
      accessor: 'role',
      Header: 't(customer_key)',
      headerProps: {
        className: 'py-3 ps-0',
      },
      cellProps: {
        className: 'ps-3 ',
      },
      Cell: (rowData: any) => {
        return <SoftBadge>{rowData.row.original.role}</SoftBadge>;
      },
    },
  ];
};
const isNull = (arg: string | undefined) => {
  return arg ? arg : '';
};
const UserItem = ({ user }: UserItemProps) => {
  const { firstname, lastname, guid } = user;

  return (
    <span className="text-800">
      <Link to={`/customeradmin/edituser/${guid}`} className="text-800">
        <Flex
          alignItems="center"
          alignContent={undefined}
          inline={undefined}
          wrap={undefined}
          className={undefined}
          breakpoint={undefined}
          direction={undefined}
          justifyContent={undefined}
        >
          <Avatar size="xl" name={firstname} className="bg-white" />
          <h6 className="mb-0 ps-2">{`${isNull(firstname)} ${isNull(
            lastname
          )}`}</h6>
        </Flex>
      </Link>
    </span>
  );
};

export default CustomerUsersDetaisTable;
