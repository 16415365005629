import { RouteProps, Outlet } from 'react-router-dom';
//import * as userStorage from "../../../utils/userStorage";

import { Spinner } from 'react-bootstrap';
import useTokenAuthentication from 'hooks/useTokenAuthentication';

type Props = RouteProps & {
  requiresAuth: boolean;
};

const ProtectedRoute = ({ requiresAuth }: Props) => {
  const { loading, tokenIsValid } = useTokenAuthentication({ requiresAuth });
  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );
  }
  return <Outlet />;
};

export default ProtectedRoute;
