import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Input } from 'components/common/Input';
import { company_name_validation } from 'helpers/Validation/InputValidation';
import { AuthPut } from 'helpers/api/AuthCRUD';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getCustomerWithDetailsResponse } from 'types';

type Props = {
  customer: getCustomerWithDetailsResponse;
};

const CompanyNameForm = ({ customer }: Props) => {
  const { token } = useSelector((state: RootState) => state);
  const { id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const methods = useForm();
  useEffect(() => {
    //läd die default values für den customer
    if (customer) methods.reset(customer);
  }, [customer]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> =
    methods.handleSubmit(async (data) => {
      setLoading(true);
      try {
        if (token) {
          await AuthPut(`/api/Customer/${customer.guid}`, data, token);
        }
      } finally {
        setLoading(false);
      }
    });
  return (
    <FormProvider {...methods}>
      <Card className="mb-3">
        <FalconCardHeader
          title={t('profile_settings', { ns: 'benutzerliste' })}
        />
        <Card.Body className="bg-light">
          <Col className="mb-3">
            <Avatar
              name={customer.name}
              size={'4xl'}
              className={'text-white'}
            />
          </Col>

          <Form onSubmit={handleSubmit}>
            <Form.Group as={Col} controlId="name">
              <Input {...company_name_validation} withLabel />
            </Form.Group>
            <div className="text-end">
              <Button variant="primary" type="submit">
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  t('Update', { ns: 'benutzerliste' })
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
};

export default CompanyNameForm;
