/// ACHTUNG !! // NEVER TOUCH //
/// Wenn hier neue Parameter hinzukommen oder welche abgeändert werden müssen
/// dann müssen diese auch in allen Brands (Kunden - Repositories) hinzugefügt werden.
/// Und noch mal Achtung. Ein update auf dieser Datei zerstörd das Brand. Damit werden die Brand-Spezifischen
/// Einstellungen überschrieben. Also mit Vorsicht genießen.

// Brand options
export const brandurl = "www.flowster.de";
export const downloadUrl = "https://files.flowster.de/";
export const filesDownloadToken = "h6y2zd7fix3smc9r4lqtjkeuv5a1bo";
export const filesDownloadSecret = "p72wfnd5lzxyao6";

// General options
export const version = "1.0.0";
export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: "vertical",
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: "transparent",
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
