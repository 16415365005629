import ImprintLink from "components/custom/ImprintLink";
import LanguageSwitcher from "components/i18n/LanguageSwitcher";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../../assets/font.css";
const Footer = () => {
  const version = process.env.REACT_APP_VERSION;
  const location = useLocation();
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            &copy; FLOWSTER Solutions GmbH | <br className="d-sm-none" />{" "}
            {new Date().getFullYear()}
          </p>
        </Col>

        <Col sm={6} md={4}>
          <Row className="w-100 d-flex justify-content-end">
            {location.pathname !== "/sign-in" && (
              <Col xs={6} className="">
                <LanguageSwitcher />
              </Col>
            )}
            <Col xs={6}>
              <ImprintLink />
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
