import { AuthGet } from 'helpers/api/AuthCRUD';
import { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { useAsyncDebounce } from 'react-table';
import { RootState } from 'store';
import { CustomerGetAllResponse } from 'types';

type Props = {
  setSelectedCustomer: Dispatch<SetStateAction<string | null>>;
};

export default function CustomerDropdowns({ setSelectedCustomer }: Props) {
  const { t } = useTranslation('licenses');
  const { token } = useSelector((state: RootState) => state);
  const queryFetch = async (inputValue: any) => {
    try {
      if (!token) return [];
      const response: CustomerGetAllResponse[] = await AuthGet(
        '/api/Customer/GetAll',
        token
      );

      const Items: { value: string; label: string }[] = response.map(
        (customer) => {
          return { value: customer.guid, label: customer.name };
        }
      );

      return Items.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ).sort((a, b) => a.label.localeCompare(b.label));
    } catch (error) {
      console.error(error); // Log the error message instead of console.error();
      return [];
    }
  };

  const loadOptions = async (inputValue: string) => {
    return await queryFetch(inputValue);
  };
  const debouncedLoadOptions = useAsyncDebounce(loadOptions, 1000);

  return (
    <>
      <Form.Label className="mb-1 mt-2 fs--1">{t('Customer')}</Form.Label>
      <AsyncSelect
        classNamePrefix="react-select"
        defaultValue={{
          value: null,
          label: 'none',
        }}
        isClearable
        cacheOptions
        defaultOptions
        placeholder={t('Select...')}
        //@ts-ignore
        loadOptions={debouncedLoadOptions}
        onChange={(e) => {
          setSelectedCustomer(e && e.value);
        }}
      />
    </>
  );
}
