import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const ImprintLink = () => {
  const location = useLocation();
  const {t} = useTranslation("login")

  return (
    <Link
      className="fs--1 mb-0"
      to={location.pathname === "/sign-in" ? "/imprint" : "/app/imprint"}
      target={"_self"}
    >
      {t("impressum", { ns: "login" })}
    </Link>
  );
};

export default ImprintLink;
