import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import 'i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';

const container = document.getElementById('main')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Main>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Main>
  </React.StrictMode>
);
