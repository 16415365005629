import { env_public_url } from 'env_Variables';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Root from 'Routes/RouteLayout';
const App = () => {
  return (
    <Router basename={'/'} >
      <Root />
    </Router>
  );
};

export default App;
