import { createSlice } from '@reduxjs/toolkit';
import { GetSingleCustomerWithUsersResponse } from 'types';

const initialState: GetSingleCustomerWithUsersResponse[] = [];
const customersItemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setCustomerResponse: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setCustomerResponse } = customersItemsSlice.actions;
export default customersItemsSlice.reducer;
