/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage,
}) => {
  const totalPages = pageCount; // Total number of pages
  const visiblePages = 6; // Number of visible pages including the current page

  // Calculate the starting and ending indexes
  let startPage = Math.max(pageIndex - 2, 1);
  let endPage = Math.min(startPage + visiblePages, totalPages);
  // Adjust the startPage if the endPage is too close to the totalPages
  startPage = Math.max(endPage - visiblePages, 0);

  // Adjust the endPage if the startPage is too close to 0
  endPage = Math.min(startPage + visiblePages, totalPages);
  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2 d-flex ">
        {Array.from(Array(pageCount).keys()).map((page) => {
          const pageNumber = page + 1;
          if (pageNumber >= startPage && pageNumber <= endPage) {
            return (
              <li
                key={page}
                className={classNames({ active: pageIndex === page })}
              >
                <Button
                  size="sm"
                  variant={pageIndex === page ? "primary'" : 'falcon-default'}
                  className={classNames(
                    'page',
                    {
                      'me-2': pageNumber !== pageCount,
                    },
                    { 'btn-primary': pageIndex === page }
                  )}
                  onClick={() => gotoPage(page)}
                >
                  {pageNumber}
                </Button>
              </li>
            );
          }
          return null;
        })}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
