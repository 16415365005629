import Flex from 'components/common/Flex';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import CardDropdown from 'components/common/CardDropdown';
import { AuthUpload } from 'helpers/api/AuthCRUD';
import { getSize } from 'helpers/utils';
import { Accordion, Button, Card, Dropdown, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { productTypes } from 'types';
import * as yup from 'yup';
import CustomerDropdowns from './CustomerDropdowns';
import ProductDropdown, { backendProductTypes } from './ProductDropdown';
import LicenceTypeDropdown from './LicenceTypeDropdown';
type Props = {
  refetch: () => void;
};

const SingelFileUpload = ({ refetch }: Props) => {
  const { t } = useTranslation('licenses');
  const { token } = useSelector((state: RootState) => state);
  const [productType, setProductType] =
    useState<backendProductTypes>('FlowsterStudio');
  const [licenseType, setLicenseType] = useState<string>('');
  const [customerGuid, setSelectedCustomer] = useState<string | null>(null);

  const schema = yup
    .object({
      uploadedFiles: yup.array().required('This field is required.'),
    })
    .required();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [files, setFiles] = useState<File[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/x-iwork-keynote-sffkey': ['.key'],
    },
    onDrop: (acceptedFiles) => {
      if (errors.uploadedFiles) {
        clearErrors('uploadedFiles');
      }
      setValue('uploadedFiles', acceptedFiles);
      setFiles([...acceptedFiles]);
    },
  });
  const handleRemove = (name: string) => {
    const filteredFiles = files.filter((file) => file.name !== name);
    setFiles(filteredFiles);
    setValue('uploadedFiles', filteredFiles.length ? filteredFiles : []);
  };
  type SubmitProps = {
    uploadedFiles: File[];
  };
  const onSubmit = async (data: SubmitProps) => {
    if (!token || !data) return;
    const formData = new FormData();
    data.uploadedFiles.forEach((file) => {
      formData.append('uploadedFiles', file);
    });

    // Create an empty searchParams object
    const searchParams = new URLSearchParams();

    // Add the 'licence_type' parameter only if licenseType is not empty
    if (licenseType.trim() !== '') {
      searchParams.set('licence_type', licenseType.trim());
    }

    // Construct the URL
    let url = `/api/Licence/upload/${productType}`;

    // Append the customerGuid
    url += `/${customerGuid}`;
    // Append search parameters if they exist
    if (searchParams.toString() !== '') {
      url += `?${searchParams.toString()}`;
    }
    const response = await AuthUpload(url, formData, token);
    if (response === 'success') {
      setFiles([]);
      setValue('uploadedFiles', []);
      refetch();
    }
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t('Upload')}</Accordion.Header>
        <Accordion.Body>
          <CustomerDropdowns setSelectedCustomer={setSelectedCustomer} />
          <ProductDropdown setProductType={setProductType} />
          <LicenceTypeDropdown
            software_type={productType}
            setLicenseType={setLicenseType}
          />

          <Form className="mt-5 " onSubmit={handleSubmit(onSubmit)}>
            <div
              {...getRootProps({
                className: `${classNames('dropzone-area py-6', {
                  'is-invalid': errors.uploadedFiles,
                })}`,
              })}
            >
              <input
                name="uploadedFiles"
                {...getInputProps({ multiple: false })}
              />
              <Flex justifyContent="center" alignItems="center">
                {/* <img src={cloudUpload} alt="" width={25} className="me-2" /> */}
                <AiOutlineCloudUpload className="me-2 display-4" />

                <p className="fs-0 mb-0 text-700">
                  {t('drop_licence_download')}
                </p>
              </Flex>
            </div>
            <div className="invalid-feedback">
              {errors.uploadedFiles?.message}
            </div>
            <div>
              {files.map((file) => (
                <Flex
                  alignItems="center"
                  className="py-3 border-bottom btn-reveal-trigger"
                  key={file.name}
                >
                  {/* <img
              className="rounded"
              width={40}
              height={40}
              src={file.preview}
              alt={file.name}
            /> */}
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{file.name}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>

                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(file.name)}
                      >
                        {t('Remove')}
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              ))}
            </div>
            <br />
            <Button
              disabled={!customerGuid}
              variant="falcon-primary"
              type="submit"
              className="me-2"
              size="sm"
            >
              {t('Submit')}
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SingelFileUpload;
