import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { env_backend_url } from "env_Variables";
import { setUser } from "store/user/userSlice";
import { setToken } from "store/Token/tokeSlice";
import routes from "routing/routes";
import { RootState } from "store";

type Props = {
  requiresAuth: boolean;
  token?: string;
};

async function checkTokenFetchUser(token: string) {
  try {
    const response = await axios.get(`${env_backend_url}/api/IsValid`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.debug("Invalid token - clearing storage");
    localStorage.removeItem("token");
    return null;
  }
}

const useTokenAuthentication = ({ requiresAuth, token }: Props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tokenIsValid, setTokenIsValid] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      try {
        if (requiresAuth) {
          const oneToken = token || localStorage.getItem("token");
          if (!oneToken) {
            navigation(routes.unsigned.signin);
            return;
          }

          const user = await checkTokenFetchUser(oneToken);
          if (!user) {
            navigation(routes.unsigned.signin);
            setTokenIsValid(false);
          } else {
            dispatch(setUser(user));
            setTokenIsValid(true);
          }
        }
      } catch (error) {
        console.debug("Something went wrong in the authentication process");
        navigation(routes.unsigned.signin);
      } finally {
        setLoading(false);
      }
    };

    authenticate();
  }, [requiresAuth, token, dispatch, navigation]);

  return { loading, tokenIsValid };
};

export default useTokenAuthentication;
