import useData from 'hooks/useData';
import { Dispatch, SetStateAction } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { productTypes } from 'types';

type Props = {
  setProductType: Dispatch<SetStateAction<backendProductTypes>>;
};
export type backendProductTypes =
  | 'FlowsterStudio'
  | 'FSM'
  | 'PasswordResetPortal';
export default function ProductDropdown({ setProductType }: Props) {
  const { t } = useTranslation('licenses');
  const { data, error, loading } = useData<backendProductTypes[]>(
    '/api/Licence/software_types'
  );

  if (loading)
    return (
      <>
        <Spinner />
      </>
    );
  const options = data
    ?.map((product) => ({
      label: product,
      value: product,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <>
      <Form.Label className="mb-1 mt-2 fs--1">{t('product')}</Form.Label>
      {options && (
        <Select
          classNamePrefix="react-select"
          defaultValue={options[0]}
          options={options}
          onChange={(e) => {
            if (!e) return;
            setProductType(e.value as backendProductTypes);
          }}
        />
      )}
      {/* {data ? (
        <Form.Select
          aria-label="Default select example"
          onChange={({ target: { value } }) =>
            setProductType(value as productTypes)
          }
          defaultValue={data[0]}
        >
          {data.map((ele) => (
            <option key={ele} value={ele}>
              {ele}
            </option>
          ))}
        </Form.Select>
      ) : (
        <></>
      )} */}
    </>
  );
}
