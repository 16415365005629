import {
  faCertificate,
  faUserAlt,
  faUserCircle,
  faUserFriends,
  faUsers,
  faUser,
  faAddressCard,
} from '@fortawesome/free-solid-svg-icons';

export const routes = {
  base: '/',
  unsigned: {
    signin: '/sign-in',
    reset: '/reset/:token',
    forgotPassword: '/forgot-password',
    imprint: '/imprint',
  },
  signed: {
    application: '/app',
    notfound: '/app/404',
    error: '/app/500',
    download: '/product/download',
    license: '/product/license',
    profile: '/settings/profile',
    company: '/settings/company',
    support: '/product/support',
    imprint: 'app/imprint',
  },
  admin: {
    index: '/admin',
    users: 'Users',
    customers: 'Customers',
    Licenses: 'Licenses',
    application: 'app',
    edituser: 'edituser/:id',
    editcustomer: 'editcustomer/:id',
    showcustomer: 'showcustomer/:id',
  },
  customeradmin: {
    index: '/customeradmin',
    editcustomer: 'editcustomer',
    edituser: 'edituser/:id',
  },
};
export const allowedAll = ['admin', 'customeradmin', 'user'];
export const onlyAdmin = ['admin'];
export const onlyCustomerAdmin = ['customeradmin'];
export const navRoutes = [
  {
    label: 'general',
    labelDisable: true,
    allowed: allowedAll,
    children: [
      {
        name: 'Home',
        icon: 'home',
        to: '/app',
        active: true,
        allowedUser: allowedAll,
      },
    ],
  },
  {
    label: 'Produkt',
    allowed: allowedAll,
    children: [
      {
        name: 'Download',
        icon: 'download',
        to: '/product/download',
        active: true,
        allowedUser: allowedAll,
      },
      {
        name: 'Lizenz',
        icon: 'paperclip',
        to: '/product/license',
        active: true,
        allowedUser: allowedAll,
      },
      {
        name: 'Support',
        icon: 'phone',
        to: '/product/support',
        active: true,
        allowedUser: allowedAll,
      },
    ],
  },
  {
    label: 'settings',
    allowed: allowedAll,
    children: [
      {
        name: 'customer_data',
        icon: 'book',
        to: '/settings/company',
        active: true,
        allowedUser: allowedAll,
      },
      {
        name: 'user_data',
        icon: 'user',
        to: '/settings/profile',
        active: true,
        allowedUser: allowedAll,
      },
    ],
  },
  {
    label: 'Admin',
    allowed: onlyAdmin,
    children: [
      {
        name: 'assign_licenses',
        icon: faCertificate,
        to: '/admin/licenses',
        active: true,
        allowedUser: onlyAdmin,
      },
      {
        name: 'Benutzerliste',
        icon: faUsers,
        to: '/admin/users',
        active: true,
        allowedUser: onlyAdmin,
      },
      {
        name: 'Kundenliste',
        icon: faAddressCard,
        to: '/admin/customers',
        active: true,
        allowedUser: onlyAdmin,
      },
    ],
  },
  {
    label: 'CustomerAdmin',
    allowed: onlyCustomerAdmin,
    children: [
      {
        name: 'Customer',
        icon: 'book',
        to: '/customeradmin/editcustomer',
        active: true,
        allowedUser: onlyCustomerAdmin,
      },
    ],
  },
];

export default routes;
