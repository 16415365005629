import FalconCardHeader from 'components/common/FalconCardHeader';
import { Input } from 'components/common/Input';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  state_validation,
  country_validation,
  PLZ_validation,
  city_validation,
  street_validation,
} from 'helpers/Validation/InputValidation';
import React from 'react';
import { Card } from 'react-bootstrap';

import { Address } from 'types';
import AddAddressModal from './AddAddressModal';
import IconItem from 'components/common/icon/IconItem';
import CardDropdown from 'components/common/CardDropdown';
import { AuthDel } from 'helpers/api/AuthCRUD';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import EditAddressModal from './EditAddressModal';

type MainComProps = {
  addresses: Address[] | undefined;
  refetch: () => void;
  customerGuid: string
};
type ColumsProps = {
  removeAddress: (id: any) => void;
  refetch: () => void;
};

const columns = ({ removeAddress, refetch }: ColumsProps) => {
  return [
    {
      accessor: 'strasse',
      Header: 'Straße',
      headerProps: { className: 'ps-2', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
      },
      Cell: (rowData: any) => {
        const { strasse } = rowData.row.original;
        return <h6 className="mb-0 text-900">{strasse}</h6>;
      },
    },
    {
      accessor: 'plz',
      Header: 'postal_code',
      headerProps: { style: { minWidth: '14.625rem' } },
      cellProps: {
        className: 'py-2 pe-4',
      },
      Cell: (rowData: any) => {
        const { plz } = rowData.row.original;
        return <h6 className="mb-0 text-900">{plz}</h6>;
      },
    },
    {
      accessor: 'stadt',
      Header: 'city',
      cellProps: {
        className: 'fs-0 pe-4',
      },
      Cell: (rowData: any) => {
        const { stadt } = rowData.row.original;
        return <h6 className="mb-0 text-900">{stadt}</h6>;
      },
    },
    {
      accessor: 'land',
      Header: 'country',
      cellProps: {
        className: 'pe-4',
      },
      Cell: (rowData: any) => {
        const { land } = rowData.row.original;
        return <h6 className="mb-0 text-900">{land}</h6>;
      },
    },
    {
      accessor: 'bundesland',
      Header: 'state',
      Cell: (rowData: any) => {
        const { bundesland } = rowData.row.original;
        return <h6 className="mb-0 text-900">{bundesland}</h6>;
      },
    },
    {
      accessor: 'Action',
      Header: 'Action',
      headerProps: {
        className: 'text-end',
      },
      cellProps: {
        className: 'text-end',
      },
      disableSortBy: true,
      Cell: (rowData: any) => {
        const { guid } = rowData.row.original;
        return (
          <div>
            {/*
             //TODO: Edit Address from
            Customer
    */}
            <div className="hover-actions bg-100 ">
              <EditAddressModal user={rowData.row.original} refetch={refetch} />

              <IconItem
                tag="button"
                icon={['fas', 'trash']}
                size="sm"
                className="btn rounded-3 me-2 fs--2"
                bg={undefined}
                color={undefined}
                transform={undefined}
                iconClass={undefined}
                onClick={() => removeAddress(guid)}
              />
            </div>
            <CardDropdown btnRevealClass="btn-reveal-sm" drop="start" />
          </div>
        );
      },
    },
  ];
};
const AddressTable = ({ addresses, refetch, customerGuid }: MainComProps) => {
  const { token } = useSelector((state: RootState) => state);

  const removeAddress = async (guid: any) => {
    if (!token) return;
    await AuthDel(`/api/Customer/address/${guid}`, {}, token);
    refetch();
  };
  const prosp = {
    removeAddress,
    refetch,
  };
  return (
    <AdvanceTableWrapper
      //@ts-ignore
      rowCount={addresses.length}
      columns={columns(prosp)}
      data={addresses}
      sortable
      // selection
      pagination={true}
      perPage={8}
      selectionColumnWidth={10}
    >
      <Card className="mb-xl-3">
        <FalconCardHeader
          title="Addresses"
          titleTag="h6"
          endEl={<AddAddressModal refetch={refetch} customerGuid={customerGuid} />}
        />
        <Card.Body>
          <AdvanceTable
            //@ts-ignore

            table
            // headerClassName="d-none"
            rowClassName=" align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100 "
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden',
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            //@ts-ignore
            table
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default AddressTable;
