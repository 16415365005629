import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const Imprint = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="h-100 align-items-center">
            <Col>
              {history.length > 1 && location?.pathname != "/app/imprint" && (
                <Button onClick={() => history.back()}>
                  <FontAwesomeIcon icon="chevron-left" />
                </Button>
              )}
              <h2 style={{ paddingBottom: 10, paddingTop: 20 }}>
                {t("impressum", { ns: "login" })}
              </h2>
              <p>
                <strong>Angaben gemäß § 5 TMG:</strong>
              </p>
              <p className="font-sans-serif">
                FLOWSTER Solutions GmbH
                <br />
                Vertreten durch: Matthias Jaekel
              </p>
              <p>
                <strong>Kontakt:</strong>
              </p>
              <p className="font-sans-serif">
                Tel: +49 331 231841–00
                <br />
                Fax: +49 331 231841–99
                <br />
                E-Mail: info@flowster.de
                <br />
                Web: www.flowster.de
              </p>
              <p>
                <strong>Anschrift:</strong>
              </p>
              <p className="font-sans-serif">
                August-Bebel-Straße 26-53
                <br />
                Fax: +49 331 231841–99
                <br />
                D-14482 Potsdam
                <br />
                <br />
                Amtsgericht: Potsdam
                <br />
                HRB: 26628 P
                <br />
                Umsatzsteueridentifikations-Nr.: DE292234107
                <br />
                Steuer Nr.: 046/108/08412
              </p>
              <p>
                <strong>HAFTUNGSAUSSCHLUSS</strong>
              </p>
              <p>
                <strong>Haftung für Inhalte</strong>
              </p>
              <p className="font-sans-serif">
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.
              </p>
              <p>
                <strong>Haftung für Links</strong>
              </p>
              <p className="font-sans-serif">
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
              </p>
              <p>
                <strong>Urheberrecht</strong>
              </p>
              <p className="font-sans-serif">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
              </p>
              <p>
                <strong>
                  Rechte an Markennamen, Logos und Markenbegriffen
                </strong>
              </p>
              <p className="font-sans-serif">
                Alle innerhalb des Internetangebotes genannten Warenzeichen und
                Markennamen sind eingetragene Warenzeichen und Markennamen der
                jeweiligen Hersteller. Sie unterliegen uneingeschränkt den
                Bestimmungen des jeweils gültigen Kennzeichenrechts und den
                Besitzrechten der jeweils eingetragenen Eigentümer. Alle
                Produkte, Schriftarten sowie Firmennamen und Logos sind
                Warenzeichen oder eingetragene Warenzeichen der betreffenden
                Unternehmen.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
