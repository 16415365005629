import { settings } from 'config';
import { createContext, Dispatch } from 'react';

type AppContextType = {
  config: typeof settings;
  setConfig: (key: any, value: any) => void;
  configDispatch: Dispatch<any>;
};
export const AppContext = createContext<AppContextType | null>({
  config: settings,
  setConfig: () => null,
  configDispatch: () => null,
});
