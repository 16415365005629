import {
  faCopy,
  faEye,
  faEyeSlash,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import { Input } from 'components/common/Input';
import {
  contactPhone_validation,
  email_validation,
  firstname_validation,
  lastname_validation,
} from 'helpers/Validation/InputValidation';
import { AuthPost } from 'helpers/api/AuthCRUD';
import { generateSecurePassword } from 'helpers/generatePassword';
import { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

function AddUserModal() {
  const { t } = useTranslation('labels');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [error, setError] = useState<Error>();
  const [errorPassword, setErrorPassword] = useState(false);
  const [welcomeMail, setSendWelcomeMail] = useState(false);
  const [copyStatus, setCopyStatus] = useState('');

  const { token } = useSelector((state: RootState) => state);

  const onSubmit = methods.handleSubmit(async (data) => {
    const re = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/);
    if (data?.password != undefined && !re.test(data?.password)) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
      setLoading(true);
      if (welcomeMail) data.password = null;
      if (token) {
        const response = await AuthPost('/api/Users/CreateUser', data, token);
        setLoading(false);
        if (response) setShow(false);
      }
    }
  });

  function setPassword() {
    setCopyStatus('');
    const password = generateSecurePassword();
    methods.setValue('password', password, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus('copied_to_clipboard');
    } catch (error) {
      setCopyStatus('Failed to copy to clipboard!');
      console.error('Copy failed:', error);
    }
  };

  return (
    <>
      <IconButton
        iconClassName={''}
        onClick={handleShow}
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
        className="mx-2"
        iconalign="middle"
      >
        <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
          {t('new')}
        </span>
      </IconButton>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('create_new_user')}</Modal.Title>
          <FalconCloseButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            className="mx-2"
            iconalign="middle"
            noOutline={false}
            onClick={handleClose}
          />
        </Modal.Header>
        <FormProvider {...methods}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <Input
                    {...firstname_validation}
                    withLabel
                    disabled={loading}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    {...lastname_validation}
                    withLabel
                    disabled={loading}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Input {...email_validation} withLabel disabled={loading} />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    onChange={() => setSendWelcomeMail(!welcomeMail)}
                    label={t('send_a_welcome_mail')}
                  />
                  {!welcomeMail && (
                    <>
                      <InputGroup className="col-lg-3">
                        <InputGroup.Text
                          onClick={() =>
                            setPasswordType(
                              passwordType == 'password' ? 'text' : 'password'
                            )
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon
                            icon={
                              passwordType == 'password' ? faEye : faEyeSlash
                            }
                            style={{ minWidth: 30 }}
                          />
                        </InputGroup.Text>
                        <FormControl
                          value={methods.getValues('password')}
                          onChange={(e) => {
                            methods.setValue('password', e.target.value, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }}
                          autoComplete="new-password"
                          name="password"
                          id="password"
                          required={!welcomeMail}
                          minLength={8}
                          type={passwordType}
                          isInvalid={errorPassword}
                        />
                        <Button
                          disabled={loading}
                          //@ts-ignore
                          title={t('generate_password')}
                        >
                          <FontAwesomeIcon
                            icon={faSyncAlt}
                            onClick={setPassword}
                          />
                        </Button>
                        <Button
                          style={{ marginLeft: 1 }}
                          //@ts-ignore
                          title={t('Copy')}
                          className="btn-secondary"
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => {
                              copyToClipboard(methods.getValues('password'));
                            }}
                          />
                        </Button>
                      </InputGroup>
                      {copyStatus && (
                        <Alert variant="info" style={{ marginTop: 10 }}>
                          {/* @ts-ignore */}
                          {t(copyStatus)}
                        </Alert>
                      )}
                      {errorPassword && (
                        <Alert variant="danger" style={{ marginTop: 10 }}>
                          {t('password_error_msg')}
                        </Alert>
                      )}
                      <br />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Input {...contactPhone_validation} withLabel />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t('close')}
              </Button>
              <Button type="submit" variant="primary">
                {loading ? <Spinner size="sm" /> : t('create')}
              </Button>
            </Modal.Footer>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
}
export default AddUserModal;
