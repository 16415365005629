import { toast } from 'react-toastify';

export type ToastType = 'error' | 'success' | 'info' | 'warn';
export const toastTypeArray = ['error', 'success', 'info', 'warn'];

export function toaster(type: ToastType, message: string) {
  if (toastTypeArray.includes(type)) {
    return toast[type](message);
  } else {
    return;
  }
}
