import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';
import login_de from './locales/de/login.json';
import login_en from './locales/en/login.json';
import login_fr from './locales/fr/login.json';
import support_de from './locales/de/support.json';
import support_en from './locales/en/support.json';
import support_fr from './locales/fr/support.json';
import downloads_de from './locales/de/downloads.json';
import downloads_en from './locales/en/downloads.json';
import downloads_fr from './locales/fr/downloads.json';
import home_de from './locales/de/home.json';
import home_en from './locales/en/home.json';
import home_fr from './locales/fr/home.json';
import license_de from './locales/de/license.json';
import license_en from './locales/en/license.json';
import license_fr from './locales/fr/license.json';
import routes_de from './locales/de/routes.json';
import routes_en from './locales/en/routes.json';
import routes_fr from './locales/fr/routes.json';
import labels_de from './locales/de/inputLabels.json';
import labels_en from './locales/en/inputLabels.json';
import labels_fr from './locales/fr/inputLabels.json';
import profile_de from './locales/de/profile.json';
import profile_en from './locales/en/profile.json';
import profile_fr from './locales/fr/profile.json';
import benutzerliste_de from './locales/de/benutzerliste.json';
import benutzerliste_en from './locales/en/benutzerliste.json';
import benutzerliste_fr from './locales/fr/benutzerliste.json';
export const common = {
  de,
  en,
  fr,
};
export const login = { de: login_de, en: login_en, fr: login_fr };
export const support = { de: support_de, en: support_en, fr: support_fr };
export const downloads = {
  de: downloads_de,
  en: downloads_en,
  fr: downloads_fr,
};
export const home = {
  de: home_de,
  en: home_en,
  fr: home_fr,
};
export const licenses = { de: license_de, en: license_en, fr: license_fr };
export const routes = { de: routes_de, en: routes_en, fr: routes_fr };
export const labels = { de: labels_de, en: labels_en, fr: labels_fr };
export const profile = { de: profile_de, en: profile_en, fr: profile_fr };
export const benutzerliste = {
  de: benutzerliste_de,
  en: benutzerliste_en,
  fr: benutzerliste_fr,
};
