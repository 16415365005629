import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import { Input } from 'components/common/Input';
import IconItem from 'components/common/icon/IconItem';
import {
  state_validation,
  country_validation,
  PLZ_validation,
  city_validation,
  street_validation,
} from 'helpers/Validation/InputValidation';
import { AuthGet, AuthPost, AuthPut } from 'helpers/api/AuthCRUD';
import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { Form, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useAsyncDebounce, useTable } from 'react-table';
import { RootState } from 'store';
import {
  Address,
  CreateCustomerRequest,
  UpdateUserRequest,
  UserDTO,
} from 'types';

const isNull = (arg: string | undefined | null) => {
  return arg ? arg : '';
};
type Props = {
  user: UserDTO;
  refetch: () => void;
};
export default function EditAddressModal({ user, refetch }: Props) {
  const { t } = useTranslation('translation');
  const { id: customerId } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const methods = useForm<Address>();

  const { token } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(false);

  const CreateAddress = methods.handleSubmit(async (data) => {
    setLoading(true);
    if (token) {
      const response = await AuthPut(
        `/api/Customer/${customerId}`,
        { address: data },
        token
      );
      if (response) {
        //Cleanup after the creating of a address
        handleClose();
        refetch();
        methods.reset();
        setLoading(false);
      }
    }
  });
  useEffect(() => {
    if (user) {
      methods.reset(user);
    }
  }, [user]);

  return (
    <>
      <IconItem
        tag="button"
        icon={['far', 'edit']}
        size="sm"
        className="btn rounded-3 me-2 fs--2"
        bg={undefined}
        color={undefined}
        transform={undefined}
        iconClass={undefined}
        onClick={handleShow}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('add_address_to_customer')}</Modal.Title>
          <FalconCloseButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            className="mx-2"
            iconalign="middle"
            noOutline={false}
            onClick={handleClose}
          />
        </Modal.Header>
        <FormProvider {...methods}>
          <Form onSubmit={() => CreateAddress()}>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <Input {...street_validation} withLabel />
                </Col>

                <Col lg={6}>
                  <Input {...PLZ_validation} withLabel />
                </Col>
                <Col lg={6}>
                  <Input {...city_validation} withLabel />
                </Col>
                <Col lg={6}>
                  <Input {...country_validation} withLabel />
                </Col>
                <Col lg={6}>
                  <Input {...state_validation} withLabel />
                </Col>
              </Row>
              <div className="text-end">
                <Button
                  // onClick={UpdateTheCustomer}
                  variant="primary"
                  type="submit"
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : (
                    t('Update', { ns: 'benutzerliste' })
                  )}
                </Button>
              </div>
            </Modal.Body>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
}
