export function generateSecurePassword() {
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*()_-+=<>?';

  const getRandomChar = (characters: string) =>
    characters[Math.floor(Math.random() * characters.length)];

  let password = '';

  // Generate the password
  password += getRandomChar(uppercaseLetters); // Add an uppercase letter
  password += getRandomChar(lowercaseLetters); // Add a lowercase letter
  password += getRandomChar(numbers); // Add a number
  password += getRandomChar(specialCharacters); // Add a special character

  const remainingLength = 17 - password.length; // Calculate the remaining length
  const allCharacters =
    uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

  for (let i = 0; i < remainingLength; i++) {
    password += getRandomChar(allCharacters); // Generate the remaining characters
  }

  // Shuffle the password to ensure random order of characters
  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
}
