import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { useBreakpoints } from 'hooks/useBreakpoints';
import useData from 'hooks/useData';
import React, { useEffect, useState } from 'react';
import { Card, Col, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerResponse } from 'store/responseData/customersItemsSlice';
import AllCustomerHeader from '../../Kundeliste/AllCustomerHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import LicenceFilteringForm from './LicenceFilteringForm';
import { RootState } from 'store';
import { licenceColumns } from './columns';
import { LicenceResponseAdmin } from 'types';
import LicenceHeader from './LicenceHeader';
import { setLicences } from 'store/responseData/licenceSlice';

type Props = {
  refetch: () => void;
};

const LicenceTable = ({ refetch }: Props) => {
  const [show, setShow] = useState(false);
  const { breakpoints } = useBreakpoints();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation('licenses');
  const { licenceResponse } = useSelector((state: RootState) => state);

  if (!licenceResponse)
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  return (
    <>
      <AdvanceTableWrapper
        columns={licenceColumns({ refetch })}
        data={licenceResponse}
        selectionColumnWidth={52}
        pagination
        sortable
        perPage={11}
        //@ts-ignore
        rowCount={licenceResponse.length}
      >
        <Row className="gx-3">
          <Col>
            <Card>
              <Card.Header className="border-bottom border-200 px-0">
                <LicenceHeader
                  //@ts-ignore
                  table
                  layout="table-view"
                  handleShow={handleShow}
                />
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  //@ts-ignore
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName=" align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100 "
                  tableProps={{
                    className: 'fs--1 mb-0 overflow-hidden',
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination
                  //@ts-ignore
                  table
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </AdvanceTableWrapper>
    </>
  );
};

export default LicenceTable;
