import useData from 'hooks/useData';
import { Alert, Col, Row } from 'react-bootstrap';
import { getCustomerWithDetailsResponse } from 'types';
import AddressTable from './AddressTable';
import CompanyNameForm from './CompanyNameForm';
import CustomerUsersDetaisTable from './CustomerUsersDetaisTable';
type Props = {
  customerGuid: string | undefined;
};
const EditCustomer = ({ customerGuid }: Props) => {
  const { data: customer, refetch } = useData<getCustomerWithDetailsResponse>(
    `/api/customer/${customerGuid}/with-details`
  );

  if (!customer || customer.deleted)
    return <Alert variant="warning">Account ist Deaktiviert</Alert>;
  return (
    <>
      <Row>
        <Col lg={6} className="mb-3">
          <CompanyNameForm customer={customer} />
        </Col>
        <Col lg={6} className="mb-3">
          {customer.users && (
            <CustomerUsersDetaisTable
              users={customer.users}
              refetch={refetch}
            />
          )}
        </Col>
      </Row>

      {customerGuid && (
        <AddressTable
          addresses={customer.addresses}
          customerGuid={customerGuid}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default EditCustomer;
