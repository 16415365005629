import { PageHeader } from 'components/common/PageHeader';
import useData from 'hooks/useData';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLicences } from 'store/responseData/licenceSlice';
import { LicenceResponseAdmin } from 'types';
import LicenceTable from './Components/LicenceTable';
import SingelFileUpload from './Components/SingelFileUpload';

type Props = {};

const LizenzPage = (props: Props) => {
  const { t } = useTranslation('licenses');
  const dispatch = useDispatch();
  const { data, loading, error, refetch } =
    useData<LicenceResponseAdmin>('/api/Licence');

  useEffect(() => {
    if (data) dispatch(setLicences(data));
  }, [data]);
  const title = t('licence_upload');
  return (
    <>
      <PageHeader title={title} />
      <Row className="g-0 mb-3">
        <Col lg={12}>
          <SingelFileUpload refetch={refetch} />
        </Col>
      </Row>
      <Row>
        <Col>
          <LicenceTable refetch={refetch} />
        </Col>
      </Row>
    </>
  );
};

export default LizenzPage;
