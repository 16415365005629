import IconItem from 'components/common/icon/IconItem';
import SoftBadge from 'components/common/SoftBadge';
import { AuthDel, AuthPut } from 'helpers/api/AuthCRUD';
import { getPriorityTime } from 'helpers/calculateTime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import {
  FlowsterStudioReset,
  FSM_Licence,
  LicenceResponseAdmin,
  LicenceTypes,
} from 'types';
import { FlowsterStudioLicenceModel } from 'types/FlowsterStudioLicenceType';
import { RootState } from '../../../../../store/index';
import LicencenActionDropdown from './LicencenActionDropdown';
import { PrioritySelect } from './priority';
interface deleteLicenceBody {
  licenceGuid: string;
  customerGuid: string;
}
interface Props {
  refetch: () => void;
}
async function deleteLicence(body: deleteLicenceBody, token: string) {
  if (token && body) {
    try {
      await AuthDel('/api/Licence', body, token);
    } catch (error) {
      return null;
    }
  }
}
async function enableLicence(body: deleteLicenceBody, token: string) {
  if (token && body) {
    try {
      await AuthPut('/api/Licence', body, token);
    } catch (error) {
      return null;
    }
  }
}
export const licenceColumns = ({
  refetch,
}: Props): Column<LicenceResponseAdmin>[] => {
  const { t } = useTranslation('licenses');
  const token = useSelector((state: RootState) => state.token ?? '');
  const handleDelete = async (body: deleteLicenceBody, token: string) => {
    await deleteLicence(body, token);
    refetch();
  };
  const handleEnable = async (body: deleteLicenceBody, token: string) => {
    await enableLicence(body, token);
    refetch();
  };
  return [
    {
      Header: t('Licence'),
      accessor: 'json',
      //@ts-ignore
      headerProps: {
        className: 'ps-2',
        style: { height: '46px', minWidth: '7rem' },
      },
      cellProps: {
        className: 'py-2 pe-4 text-900',
      },
      Cell: (rowData) => {
        const { json, product } = rowData.row.original;
        const parsed: LicenceTypes = JSON.parse(json);
        if (parsed) {
          if (product === 'FSM') {
            return <>{product}</>;
          }
          if (product === 'Password Reset Portal') {
            return <>{product}</>;
          }
          if (product === 'Flowster Studio') {
            const { Subscription } = parsed as FlowsterStudioLicenceModel;
            return <>{`${product} ${Subscription.Type}`}</>;
          }
        }

        return null;
      },
    },

    {
      Header: t('Customer'),
      //@ts-ignore because he cant take deep trees in TS even doe the component alows it
      accessor: 'customer.name',
      //@ts-ignore
      headerProps: { style: { minWidth: '14.625rem' } },
      cellProps: {
        className: 'py-2 pe-4',
      },

      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const { customer } = rowData.row.original;
        if (!customer) return null;
        const { name, id, guid } = customer;
        return (
          <Link to={`/admin/editcustomer/${guid}`} className="fw-semi-bold">
            {name}
          </Link>
        );
      },
    },
    {
      Header: t('file_name'),
      //@ts-ignore because he cant take deep trees in TS even doe the component alows it
      accessor: 'fileName',
      //@ts-ignore
      cellProps: {
        className: 'py-2 pe-4',
      },

      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const { fileName } = rowData.row.original;

        return (
          <div
            className="ellipsis-text
"
            title={fileName}
          >
            {fileName}
          </div>
        );
      },
    },

    {
      Header: t('company'),
      //@ts-ignore because he cant take deep trees in TS even doe the component alows it
      accessor: '',
      //@ts-ignore

      cellProps: {
        className: 'py-2 pe-4 text-900',
      },

      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const { json, product } = rowData.row.original;
        const parsed: LicenceTypes = JSON.parse(json);
        if ('Company' in parsed) {
          return (
            <div className="ellipsis-text" title={parsed.Company}>
              {parsed.Company}
            </div>
          );
        }
        return <></>;
      },
    },
    {
      Header: t('product'),
      //@ts-ignore because he cant take deep trees in TS even doe the component alows it
      accessor: '',
      //@ts-ignore

      cellProps: {
        className: 'py-2 pe-4 text-900',
      },

      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const { json, product } = rowData.row.original;
        const parsed: LicenceTypes = JSON.parse(json);
        if ('Product' in parsed) {
          return (
            <div className="ellipsis-text" title={parsed.Product}>
              {parsed.Product}
            </div>
          );
        }
        return <></>;
      },
    },
    {
      Header: t('Remaining'),
      //@ts-ignore
      cellProps: {
        className: 'pe-4',
      },
      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const { json, product } = rowData.row.original;
        const parsed: LicenceTypes = JSON.parse(json);
        if (parsed) {
          if (product === 'FSM') {
            const { Subscription, Maintenance } = parsed as FSM_Licence;
            const priorityTimeProps = getPriorityTime(Subscription);
            return <PrioritySelect {...priorityTimeProps} />;
          }
          if (product === 'Password Reset Portal') {
            const { Subscription } = parsed as FlowsterStudioReset;
            const priorityTimeProps = getPriorityTime(Subscription);
            return <PrioritySelect {...priorityTimeProps} />;
          }
          if (product === 'Flowster Studio') {
            const { Subscription } = parsed as FlowsterStudioLicenceModel;
            const priorityTimeProps = getPriorityTime(Subscription);
            return <PrioritySelect {...priorityTimeProps} />;
          }
        }

        return null;
      },
    },

    {
      Header: 'Support',
      //@ts-ignore
      cellProps: {
        className: 'pe-4',
      },
      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const { json, product } = rowData.row.original;
        const parsed: LicenceTypes = JSON.parse(json);
        if (parsed) {
          if (product === 'FSM') {
            const { Subscription, Maintenance } = parsed as FSM_Licence;
            const priorityTimeProps = getPriorityTime(Maintenance);
            return <PrioritySelect {...priorityTimeProps} />;
          }
          if (product === 'Password Reset Portal') {
            const { Subscription, Support } = parsed as FlowsterStudioReset;
            const priorityTimeProps = getPriorityTime(Support);
            return <PrioritySelect {...priorityTimeProps} />;
          }
          if (product === 'Flowster Studio') {
            const { Subscription, Support } = parsed as FlowsterStudioReset;
            const priorityTimeProps = getPriorityTime(Support);
            return <PrioritySelect {...priorityTimeProps} />;
          }
        }

        return null;
      },
    },
    {
      accessor: 'deleted',
      Header: t('Status') as string,
      Cell: (rowData) => {
        const isDeactivated = rowData.row.original.deleted;
        if (isDeactivated)
          return (
            <SoftBadge pill bg={'secondary'} className="me-2">
              {t('Inactive')}
            </SoftBadge>
          );
        return (
          <SoftBadge pill bg={'success'} className="me-2">
            {t('Active')}
          </SoftBadge>
        );
      },
    },
    {
      Header: t('Action') as string,
      //@ts-ignore
      headerProps: {
        className: 'text-end',
      },
      cellProps: {
        className: 'text-end',
      },
      disableSortBy: true,
      Cell: (rowData: CellProps<LicenceResponseAdmin>) => {
        const data = rowData.row.original;
        const isDeactivated = rowData.row.original.deleted;
        let body: deleteLicenceBody;
        if (data.customer?.guid) {
          body = { customerGuid: data.customer?.guid, licenceGuid: data.guid };
        }

        return (
          <div>
            <div className="hover-actions bg-100 ">
              {isDeactivated ? (
                <IconItem
                  icon={['fas', 'check']}
                  tag="button"
                  size="sm"
                  className="btn rounded-3 me-2 fs--2"
                  bg={undefined}
                  color={undefined}
                  transform={undefined}
                  iconClass={undefined}
                  onClick={() => handleEnable(body, token)}
                />
              ) : (
                <IconItem
                  tag="button"
                  icon={['fas', 'trash']}
                  size="sm"
                  className="btn rounded-3 me-2 fs--2"
                  bg={undefined}
                  color={undefined}
                  transform={undefined}
                  iconClass={undefined}
                  onClick={() => handleDelete(body, token)}
                />
              )}
            </div>
            <LicencenActionDropdown
              btnRevealClass="btn-reveal-sm "
              drop="start"
            />
          </div>
        );
      },
    },
  ];
};
