import { configureStore } from '@reduxjs/toolkit';

//import userReducer from "./user/reducers";
import userSlice from './user/userSlice';
import configSlice from './config/configSlice';
import tokenSlice from './Token/tokeSlice';
import usersItemsSlice from './responseData/usersItemsSlice';
import customersItemsSlice from './responseData/customersItemsSlice';
import licenceSlice from './responseData/licenceSlice';

export const store = configureStore({
  reducer: {
    usersResponse: usersItemsSlice,
    customersResponse: customersItemsSlice,
    user: userSlice,
    token: tokenSlice,
    config: configSlice,
    licenceResponse:licenceSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export * from 'react-redux';
