import moment from 'moment';

interface PrioritySelectData {
  title: string;
  color: '#e63757' | '#F68F57' | '#2A7BE4' | '#00D27B';
  data: number;
}
function calculateDistancePercentage(startDate: string, endDate: string) {
  function getDate(stringDate: string): Date {
    const result = new Date(stringDate);
    return result;
  }

  const startDateParsed = getDate(startDate);
  const endDateParsed = getDate(endDate);
  const currentDate = new Date(); // Use any date object representing the current date

  const totalMilliseconds = endDateParsed.getTime() - startDateParsed.getTime();
  const currentMilliseconds = currentDate.getTime() - startDateParsed.getTime();
  const distancePercentage = (currentMilliseconds / totalMilliseconds) * 100;
  return Math.min(distancePercentage, 100);
}
// Create a mapping from priority titles to colors
const priorityColors: {
  [key in 'Urgent' | 'High' | 'Medium' | 'Low']: PrioritySelectData['color'];
} = {
  Urgent: '#e63757',
  High: '#F68F57',
  Medium: '#2A7BE4',
  Low: '#00D27B',
};

export function getPriorityTime({
  From,
  Till,
}: {
  From: string;
  Till: string | null;
}): PrioritySelectData {
  if (!From || !Till) {
    return {
      title: 'Open End',
      color: priorityColors['Low'],
      data: 0,
    };
  }
  const percent = calculateDistancePercentage(From, Till);

  const formattedDate = moment(Till).format('DD.MM.YYYY');

  let color;
  if (percent < 0) {
    color = priorityColors['Urgent'];
  } else if (percent < 25 && percent >= 0) {
    color = priorityColors['Low'];
  } else if (percent < 50) {
    color = priorityColors['Medium'];
  } else if (percent < 75) {
    color = priorityColors['High'];
  } else {
    color = priorityColors['Urgent'];
  }
  const data = percent >= 0 && percent < 100 ? percent : 100;

  return {
    title: formattedDate,
    color,
    data,
  };
}
