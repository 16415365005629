import { createSlice } from '@reduxjs/toolkit';
export interface usersItem {
  id: number;
  email: string;
  passwordHash: string | null;
  imageUrl: string | null;
  firstname: string | null;
  lastname: string;
  fremdId: string | null;
  contactMail: string | null;
  contactPhone: string;
  rememberMe: string | null;
  language: string | null;
  roleId: number;
  role: string;
  customer: {
    id: number;
    name: string;
  };
}

const initialState: usersItem[] = [];
const usersItemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setUsersResponse: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setUsersResponse } = usersItemsSlice.actions;
export default usersItemsSlice.reducer;
