import { english_key, french_key, german_key } from 'i18n/localesVariables';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DE, FR, GB } from 'country-flag-icons/react/3x2';

export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation();

  const locales = {
    en: {
      title: t(english_key),
      flag: (
        <GB
          title="United States"
          style={{ height: 12, marginTop: -2, paddingRight: 5 }}
        />
      ),
    },
    de: {
      title: t(german_key),
      flag: (
        <DE
          title="United States"
          style={{ height: 12, marginTop: -2, paddingRight: 5 }}
        />
      ),
    },
    fr: {
      title: t(french_key),
      flag: (
        <FR
          title="United States"
          style={{ height: 12, marginTop: -2, paddingRight: 5 }}
        />
      ),
    },
  };

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    //console.log('switch lang');
  };
  function checkLngSupport() {
    const locale = Object.keys(locales);
    const localeHas = locale.some((ele) => ele === i18n.language.split('-')[0]);
    if (localeHas) return i18n.language.split('-')[0];
    else return 'en';
  }
  return (
    <Dropdown navbar={true} onSelect={(e) => handleLanguageChange(e)}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        variant="primary"
        className="px-2 nav-link theme-control-toggle bg-transparent"
        style={{ borderColor: '#ccc' }}
      >
        {locales[checkLngSupport()].flag} {locales[checkLngSupport()].title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        {Object.keys(locales).map((locale) => (
          <Dropdown.Item key={locale} eventKey={locale}>
            {locales[locale].flag} {locales[locale].title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
