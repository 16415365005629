import { findInputError, isFormInvalid } from 'helpers/Validation';
import { Form } from 'react-bootstrap';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
interface Input {
  label: string;
  type: string;
  id: string;
  placeholder?: string;
  validation: RegisterOptions;
  name: string;
  withLabel?: boolean;
  disabled?: boolean;
}
export const Input = ({
  label,
  type,
  id,
  placeholder,
  withLabel,
  validation,
  name,
  disabled,
}: Input) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('labels');
  const inputError: any = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputError);
  return (
    <Form.Group className="mb-3" controlId={id}>
      <div className="d-grid">
        <div className="row">
          <div className="col">
            {/* @ts-ignore */}
            {withLabel && <Form.Label>{t(label)} </Form.Label>}
          </div>
        </div>
      </div>

      <Form.Control
        isInvalid={isInvalid}
        type={type}
        {...register(name, validation)}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">
        {inputError.error && t(inputError.error.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
