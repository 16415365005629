import { env_backend_url } from 'env_Variables';
import { toaster, toastTypeArray } from 'helpers/toastFactory';

import { t } from 'i18next';
import { ServerMessageResponse } from 'types';

function buildUrl(endPoint: string, queryParam?: string) {
  let url = `${env_backend_url}${endPoint}`;
  if (queryParam) {
    url += `?${queryParam}`;
  }
  return url;
}

export async function AuthPost(endPoint: string, body: object, token: string) {
  try {
    const response = await fetch(`${env_backend_url}${endPoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    if (!response.ok) {
      handleErrorResponse(response.status, data);
      return;
    }
    const { type, message } = data;
    if (toastTypeArray.includes(type)) {
      toaster(type, t(message));
      return true;
    }
  } catch (error) {
    console.error(error);
    toaster('error', 'Something went Wrong!');
  }
}

export async function AuthUpload(
  endPoint: string,
  body: BodyInit,
  token: string
) {
  try {
    const response = await fetch(`${env_backend_url}${endPoint}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    const data = await response.json();
    //console.log(data);
    if (!response.ok) {
      handleErrorResponse(response.status, data);
      return;
    }
    const { type, message } = data;
    if (toastTypeArray.includes(type)) {
      toaster(type, t(message));
      return type;
    }
  } catch (error) {
    console.error(error);
    toaster('error', 'Something went Wrong!');
  }
}

export async function AuthPut(
  endPoint: string,
  body: object,
  token: string,
  queryParam?: string
) {
  const url = buildUrl(endPoint, queryParam);
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const data = await response.json();
  if (!response.ok) {
    handleErrorResponse(response.status, data);
    return;
  }

  const { type, message } = data;
  if (toastTypeArray.includes(type)) {
    return toaster(type, t(message));
  }
}

export async function AuthDel(endPoint: string, body: object, token: string) {
  try {
    const response = await fetch(`${env_backend_url}${endPoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();

    if (!response.ok) {
      handleErrorResponse(response.status, data);
      return;
    }
    const { type, message } = data;
    if (toastTypeArray.includes(type)) {
      return toaster(type, t(message));
    }
  } catch (error) {
    console.error(error);

    toaster('error', 'Something went Wrong!');
  }
}
export async function AuthPatch(endPoint: string, body: object, token: string) {
  try {
    const response = await fetch(`${env_backend_url}${endPoint}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();

    if (!response.ok) {
      handleErrorResponse(response.status, data);
      return;
    }
    const { type, message } = data;
    if (toastTypeArray.includes(type)) {
      return toaster(type, t(message));
    }
  } catch (error) {
    console.error(error);

    toaster('error', 'Something went Wrong!');
  }
}
export async function AuthGet(endPoint: string, token: string) {
  try {
    const response = await fetch(`${env_backend_url}${endPoint}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const data = await response.json();
      handleErrorResponse(response.status, data);
      return;
    }

    return await response.json();
  } catch (error) {
    //console.log(error);
  }
}
export async function Post<T>(endPoint: string, body: T) {
  try {
    const response = await fetch(`${env_backend_url}${endPoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    if (!response.ok) {
      handleErrorResponse(response.status, data);
      return;
    }
    const { type, message } = data;
    if (toastTypeArray.includes(type)) {
      toaster(type, t(message));
      return true;
    }
  } catch (error) {
    console.error(error);
    toaster('error', 'Something went Wrong!');
  }
}
function handleErrorResponse(
  status: number,
  data: ServerMessageResponse | null
) {
  const message = data?.message;

  // @ts-ignore
  if (message) return toaster('error', t(message));
  switch (status) {
    case 400:
      toaster('error', 'Bad Request');
      break;
    case 401:
      toaster('error', 'Unauthorized');
      break;
    case 403:
      toaster('error', 'Forbidden');
      break;
    case 404:
      toaster('error', 'Not Found');
      break;
    case 500:
      toaster('error', 'Internal Server Error');
      break;
    default:
      toaster('error', `Error (${status})`);
      break;
  }
}
