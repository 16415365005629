/*-------------------------------------------------------------------
|   Input Validators 
|
|   Purpose: THIS FILE CONTAINS ALL THE VALIDATORS OBJECTS
|
|   Returns:  -
*-------------------------------------------------------------------*/
import { RegisterOptions } from 'react-hook-form';

export interface Input {
  label: string;
  type: string;
  id: string;
  placeholder?: string;
  validation: RegisterOptions;
  name: string;
  withLabel?: boolean;
}

export const name_validation: Input = {
  name: 'name',
  label: 'name',
  type: 'text',
  id: 'name',
  placeholder: 'write your name ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },

    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

export const desc_validation = {
  name: 'description',
  label: 'description',
  multiline: true,
  id: 'description',
  placeholder: 'write description ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    maxLength: {
      value: 200,
      message: '200 characters max',
    },
  },
};

export const old_password_validation: Input = {
  name: 'oldPassword',
  label: 'old_password',
  type: 'password',
  id: 'oldPassword',
  placeholder: 'type password ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
      message: 'password_error_msg',
    },
    minLength: {
      value: 8,
      message: 'min 8 characters',
    },
  },
};

export const password_validation: Input = {
  name: 'password',
  label: 'new_password',
  type: 'password',
  id: 'password',
  placeholder: 'type password ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
      message: 'password_error_msg',
    },
    minLength: {
      value: 8,
      message: 'min 8 characters',
    },
  },
};

export const password_confirmation_validation: Input = {
  name: 'confirmationPassword',
  label: 'confirm_password',
  type: 'password',
  id: 'confirmationPassword',
  placeholder: 'type password ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
      message: 'password_error_msg',
    },
    minLength: {
      value: 8,
      message: 'min 8 characters',
    },
  },
};

export const num_validation = {
  name: 'num',
  label: 'number',
  type: 'number',
  id: 'num',
  placeholder: 'write a random number',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
  },
};

export const email_validation: Input = {
  name: 'email',
  label: 'Email',
  type: 'email',
  id: 'email',
  placeholder: 'write a random email address',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'not valid',
    },
  },
};

export const contactEmail_validation: Input = {
  name: 'contactMail',
  label: 'contact_email',
  type: 'email',
  id: 'contactMail',
  placeholder: 'write a random email address',
  validation: {
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'not valid',
    },
  },
};

export const firstname_validation: Input = {
  name: 'firstname',
  label: 'firstname',
  type: 'text',
  id: 'firstname',
  placeholder: 'write your name ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },

    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

export const lastname_validation: Input = {
  name: 'lastname',
  label: 'lastname',
  type: 'text',
  id: 'lastname',
  placeholder: 'write your name ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },

    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

export const contactPhone_validation: Input = {
  name: 'contactPhone',
  label: 'contact_phone',
  type: 'tel',
  id: 'contactPhone',
  placeholder: 'write your name ...',
  validation: {
    pattern: {
      value: /^[+0-9\s-]*$/,
      message: 'not_valid_example',
    },
    minLength: {
      value: 4,
      message: 'too short',
    },
    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

export const customer_validation: Input = {
  name: 'name',
  label: 'customer_name',
  type: 'text',
  id: 'name',
  placeholder: 'CustomerName',
  validation: {
    required: {
      value: true,
      message: 'required',
    },

    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

//AddressTable
export const street_validation: Input = {
  name: 'strasse',
  label: 'street',
  type: 'text',
  id: 'strasse',
  placeholder: '',
  validation: {
    required: {
      value: true,
      message: 'required',
    },

    // maxLength: {
    //   value: 30,
    //   message: '30 characters max',
    // },
  },
};

export const PLZ_validation: Input = {
  name: 'plz',
  label: 'postal_code',
  type: 'text',
  id: 'plz',
  placeholder: 'CustomerName',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
  },
};

export const city_validation: Input = {
  name: 'stadt',
  label: 'city',
  type: 'text',
  id: 'stadt',
  placeholder: 'CustomerName',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
  },
};
export const country_validation: Input = {
  name: 'land',
  label: 'country',
  type: 'text',
  id: 'land',
  placeholder: 'CustomerName',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
  },
};

export const state_validation: Input = {
  name: 'bundesland',
  label: 'state',
  type: 'text',
  id: 'bundesland',
  placeholder: '',
  validation: {},
};

//AddressTableEnde

export const company_name_validation: Input = {
  name: 'name',
  label: 'company_name',
  type: 'text',
  id: 'name',
  placeholder: 'write your name ...',
  validation: {
    required: {
      value: true,
      message: 'required',
    },

    maxLength: {
      value: 254,
      message: '254 characters max',
    },
  },
};

export const customer_id_validation: Input = {
  name: 'customerId',
  label: 'customer_id',
  type: 'text',
  id: 'customerId',
  placeholder: 'enter the customer id ...',
  validation: {
    required: {
      value: false,
      message: 'required',
    },

    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};
