import { createSlice } from '@reduxjs/toolkit';
import { UserDTO, UserModel } from 'types';
type State = UserDTO | null
const initialState: State = null;

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState as State,
  reducers: {
    setUser: (state, action) => {
      return (state = action.payload);
    },
    updateUser: (state, action) => {
      return (state = action.payload);
    },
    removeUser: (state) => {
      return (state = initialState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, removeUser, updateUser } = userSlice.actions;

export default userSlice.reducer;
