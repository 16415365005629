export const english_key = 'languages.en';
export const german_key = 'languages.de';
export const french_key = 'languages.fr';
export const kundeportal_key = 'kundenportal';

//login.json
export const login_key = 'login';
export const remmeber_key = 'remmeber';
export const forgot_key = 'forgot';
export const impressum_key = 'impressum';
export const reset_message_key = 'reset_message';
export const resetFormText_key = 'resetFormText';
export const sendResetLink_key = 'sendResetLink';
//End login

export const email_key = 'email';
export const password_key = 'password';
export const next_key = 'next';
export const something_wrong_key = 'something_went_wrong';
export const please_provide_password = 'please_enter_your_password';
export const no_user_or_other_issue =
  'the_user_does_not_exist_or_you_made_a_typo';

//home.json
export const customer_key = 'customer';
export const profile_key = 'profile';
export const customerList_key = 'customer_list';
export const connectedUser_key = 'connected_user';
export const licenses_key = 'licenses';
export const showList_key = 'show_full_list';
//End home
