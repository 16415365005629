import { createSlice } from '@reduxjs/toolkit';
import {  LicenceResponseAdmin } from 'types';

const initialState: LicenceResponseAdmin[] = [];
const licenceSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setLicences: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setLicences } = licenceSlice.actions;
export default licenceSlice.reducer;
