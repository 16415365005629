import useData from 'hooks/useData';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { productTypes } from 'types';

type Props = {
  software_type: string;
  setLicenseType: Dispatch<SetStateAction<string>>;
};

export default function LicenceTypeDropdown({
  setLicenseType,
  software_type,
}: Props) {
  console.log('licenceType', software_type);
  const { t } = useTranslation('licenses');
  const { data, error, loading } = useData<string[]>(
    `/api/Licence/licenses_types/${software_type}`
  );
  console.log(data);
  useEffect(() => {
    if (Array.isArray(data)) setLicenseType(data[0]);
    return () => setLicenseType('');
  }, [data]);
  if (loading) return <></>;
  if (data && Array.isArray(data)) {
    const options = data
      ?.map((software) => ({
        label: software,
        value: software,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <>
        <Form.Label className="mb-1 mt-2 fs--1">{t('type')}</Form.Label>
        {options && (
          <Select
            classNamePrefix="react-select"
            defaultValue={options[0]}
            options={options}
            onChange={(e) => {
              if (!e) return;
              setLicenseType(e.value);
            }}
          />
        )}
        {/* <Form.Select
          aria-label="Default select example"
          onChange={({ target: { value } }) => setLicenseType(value)}
          defaultValue={data[0]}
        >
          {data?.map((ele) => (
            <option key={ele} value={ele}>
              {ele}
            </option>
          ))}
        </Form.Select> */}
      </>
    );
  }
  return <></>;
}
