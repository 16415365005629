import React from 'react';
import { Card, Row } from 'react-bootstrap';
export function PageHeader({ title }: { title: string }) {
  return (
    <Row className="g-0 mb-3">
      <Card className="h-100">
        <Card.Header>
          <h1 className="text-primary">{title}</h1>
        </Card.Header>
      </Card>
    </Row>
  );
}
