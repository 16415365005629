import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

const Title = ({
  titleTag: TitleTag,
  className,
  breakPoint,
  children,
  danger,
}) => (
  <TitleTag
    className={classNames(
      {
        'mb-0': !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint,
      },
      className
    )}
    style={danger ? { color: '#FFFFFF' } : {}}
  >
    {children}
  </TitleTag>
);

const FalconCardHeader = ({
  title,
  light,
  titleTag,
  titleClass,
  className,
  breakPoint,
  endEl,
  children,
  danger = false,
}) => (
  <Card.Header
    className={classNames(className, { 'bg-light': light })}
    style={danger ? { backgroundColor: '#CC0000' } : {}}
  >
    {endEl ? (
      <Row className="align-items-center">
        <Col>
          <Title
            breakPoint={breakPoint}
            titleTag={titleTag}
            className={titleClass}
          >
            {' '}
            {title}
          </Title>
          {children}
        </Col>
        <Col
          {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
          className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
        >
          {endEl}
        </Col>
      </Row>
    ) : (
      <Title
        breakPoint={breakPoint}
        titleTag={titleTag}
        className={titleClass}
        danger={danger}
      >
        {title}
      </Title>
    )}
  </Card.Header>
);

Title.propTypes = {
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = { titleTag: 'h5' };

FalconCardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  light: PropTypes.bool,
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  endEl: PropTypes.node,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FalconCardHeader;
