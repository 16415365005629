import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, redirect, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { UserDTO } from 'types';
// Type guard to check if `user` is not null
function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}
export default function CustomerAdminLayout() {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state) as {
    user: UserDTO | null;
  };

  useEffect(() => {
    if (!isNotNull(user)) navigate('/');
    if (user?.role !== 'customeradmin') navigate('/');
  }, []);

  return <Outlet />;
}
