import { env_backend_url } from 'env_Variables';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function useData<T>(endPoint: string): {
  data: T | null;
  loading: boolean;
  error: any;
  refetch: () => void;
} {
  const { token } = useSelector((state: RootState) => state);
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(false);
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    let ignore = false;
    fetch(`${env_backend_url}${endPoint}`, {
      headers: { Authorization: `Bearer ${token}` },
      signal: controller.signal,
    })
      .then((response) => response.json())
      .then((json) => {
        if (!ignore) {
          setData(json);
        }
      })
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      ignore = true;

      controller.abort();
    };
  }, [endPoint, token]);

  function refetch() {
    fetch(`${env_backend_url}${endPoint}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      })
      .catch((error) => setError(error));
  }
  return { data, loading, error, refetch };
}
