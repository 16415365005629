import { createSlice } from '@reduxjs/toolkit';
import { CleanPlugin } from 'webpack';

const initialState = localStorage.getItem('token');

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem('token', action.payload);
      return (state = action.payload);
    },
    removeToken: (state) => {
      localStorage.removeItem('token');
      return (state = initialState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, removeToken } = tokenSlice.actions;

export default tokenSlice.reducer;
